export interface AdvertiseLandingPage {
  id: number;
  advertiseId: number;
  name: string;
  landingPageCode: string;
  pcUrl: string;
  spUrl: string;
  callerDuplicateAchievementAction: string;
  createdAt: string;
  updatedAt: string;
}

export const callerDuplicateAchievementActionDisplayText = (callerDuplicateAchievementAction: string): string => {
  switch (callerDuplicateAchievementAction) {
    case 'invalid':
      return '無効';
    case 'valid':
      return '有効';
    default:
      return '-';
  }
};
